import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import Assetz63DegreeEast from "../../../components/developers/assetzdeveloper/ourproperties/Assetz63DegreeEast";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const Assetz63DegreeEastPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <Assetz63DegreeEast />
    </>
  )
}

export default Assetz63DegreeEastPage
